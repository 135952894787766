import React from "react";
import classNames from 'classnames';
import { updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { AttributeTextValue } from '@msdyn365-commerce/retail-proxy';
import { ICoreContext, IActionContext} from '@msdyn365-commerce/core';
import { ICartlinesViewProps } from '@msdyn365-commerce-modules/cart/src/modules/cart/components/cart-line-items';
import { getCartState } from '@msdyn365-commerce/global-state';
import { Button, Node} from '@msdyn365-commerce-modules/utilities';
import { ICartConfig, ICartResources } from "../../definition-extensions/cart.ext.props.autogenerated";
import { createBooleanAttribute, createTextAttribute, getAttributeValueOrDefault, getBooleanAttributeValue, getTextAttributeValue, updateBooleanAttribute, updateTextAttribute } from "../../../../utilities/attribute-utilities";
import { trusUploadImageAsync } from "../../../../custom-proxy/DataActionExtension.g";
import { getAttributeValuesAsync } from "@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g";

export interface IPaperGiftCardComponentProps {
    cartLine: ICartlinesViewProps
    allLines: ICartlinesViewProps[]
    masterProduct?: number;
    channelId?: number;
    context?: ICoreContext | undefined;
    resources?: ICartResources | undefined;
    configs?: ICartConfig | undefined;
}

export interface IPaperGiftCardComponentState {
    from?: string,
    to?: string,
    message?: string,
    image?: string,
    imageName?: string,
    isEditMode: boolean,
    isDataLoaded: boolean,
    canDiscard: boolean,
    errorMessage?: string
    isProcesssing: boolean,
    wrappingEnabled: boolean
}

export interface IGiftCardResources{
    toggleLabel?: string;
    disableGiftwrappingLabel?: string;
    enableGiftwrappingLabel?: string;
}

const needPaperGiftCardAttribute: string = "TRUS_CartLineNeedGiftCard";
const paperGiftCardFromAttribute: string = "TRUS_PaperGiftCardFrom";
const paperGiftCardToAttribute: string = "TRUS_PaperGiftCardTo";
const paperGiftCardMessageAttribute: string = "TRUS_PaperGiftCardMessage";
const paperGiftCardImageAttribute: string = "TRUS_PaperGiftCardImage";
const enableWrappingAttrName: string = "Gift Wrapping";

export class PaperGiftCard extends React.Component<IPaperGiftCardComponentProps, IPaperGiftCardComponentState> {

    constructor(props: IPaperGiftCardComponentProps, state: IPaperGiftCardComponentState) {
        super(props);
        this.state = { 
            isEditMode: false, 
            canDiscard: false,
            isDataLoaded: false,
            isProcesssing: false,
            wrappingEnabled: false,
        };
    }

    get isSaveButtonVisible(): boolean {
        return this.state.isEditMode;
    }

    get isCancelVisible(): boolean {
        return this.isSaveButtonVisible && this.state.canDiscard;
    }

    componentDidMount(): void {
        this.initializeStateFromAttributes();

        const fetchData = async () => {
            const productAttributes = await getAttributeValuesAsync({ callerContext: this.props.context?.actionContext! }, this.props.cartLine.data?.cartline.ProductId!, this.props.context?.actionContext?.requestContext?.apiSettings?.channelId!, 0);;
            const isWrappingEnabledForProduct = getAttributeValueOrDefault(productAttributes, enableWrappingAttrName, (x) => x.BooleanValue, true);

            this.setState({wrappingEnabled: isWrappingEnabledForProduct})
        };

        fetchData().catch(console.error);
    }

    initializeStateFromAttributes(): void {
        const {
            cartLine
        } = this.props;

        const from = getTextAttributeValue(cartLine?.data?.cartline?.AttributeValues!, paperGiftCardFromAttribute) ?? '';
        const to = getTextAttributeValue(cartLine?.data?.cartline?.AttributeValues!, paperGiftCardToAttribute) ?? '';
        const message = getTextAttributeValue(cartLine?.data?.cartline?.AttributeValues!, paperGiftCardMessageAttribute) ?? '';
        const image = getTextAttributeValue(cartLine?.data?.cartline?.AttributeValues!, paperGiftCardImageAttribute) ?? '';

        this.setState({
            from: from,
            to: to,
            message: message,
            image: image,
            canDiscard: !!from && !!to && !!message && !!image,
            isEditMode: false,
            isDataLoaded: true,
            isProcesssing: false,

            imageName: undefined,
            errorMessage: undefined
        })
    }

    public render(): JSX.Element {

        const {
            cartLine,
            resources,
            context
        } = this.props;
        
        const giftWrappingResources: IGiftCardResources = {
            toggleLabel: 'Free Paper Gift Card' || '',
            enableGiftwrappingLabel: resources?.enableGiftwrappingLabel || '',
            disableGiftwrappingLabel: resources?.disableGiftwrappingLabel || '',
        }
    
        return (
            <>
            {
                this.state.wrappingEnabled &&
                <Node className="msc-cart-line__giftwrapping-container msc-cart-line__papergiftcard-container" >
                    {
                        this.renderGiftWrappingToggle(cartLine, context!, giftWrappingResources)
                    }
                    {
                        isWrappingNeeded(cartLine) && this.state.isDataLoaded && this.renderGiftCardInputs()
                    }
                </Node>
            }
            </>
        );
    }

    private readonly onFromInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            from: e.target.value,
            isEditMode: true,
            errorMessage: undefined
        });
    };

    private readonly onToInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            to: e.target.value,
            isEditMode: true,
            errorMessage: undefined
        });
    };

    private readonly onMessageInputChangeHandler = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            message: e.target.value,
            isEditMode: true,
            errorMessage: undefined
        });
    };

    private readonly onImageInputChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files && event.target.files.length > 0 && event.target.files[0]){
            const reader = new FileReader();
            const imageFile = event.target.files[0];
            const fileName = imageFile.name;
            reader.onload = (ev: any) => {
                this.setState({
                    image: ev.target.result,
                    imageName: fileName,
                    isEditMode: true,
                    errorMessage: undefined
                });
            };
            reader.onerror = (ev: any) => {
                this.setState({
                    errorMessage: "Error while uploading image, please try again or choose different image format"
                });
            };
            reader.readAsDataURL(imageFile);
            
            //clean file input
            ///@ts-ignore
            event.target.value = null;
        }
    };

    private readonly cleanImageHandler = () => {
        this.setState({
            isEditMode: true,
            image: undefined,
            imageName: undefined,
            errorMessage: undefined          
        });
    };

    private readonly savePaperGiftCardInfo = async () => {
        const { to, from, message, image, imageName } = this.state;
        let imageUrl = image;
        
        if (to && from && message) {

            this.setState({ isProcesssing: true })

            if (imageName) {
                imageUrl = await uploadPaperGiftCardImage(this.props.context?.actionContext!, image!, imageName!);
                if (!imageUrl) {
                    this.setState({ errorMessage: "Error occured while saving image. Please try image with png or jpg format", isProcesssing: false })
                    return;
                }
            }
            await updatePaperGiftCardInfo(this.props.cartLine, this.props.context!, to!, from!, message!, imageUrl!);
            this.initializeStateFromAttributes();
            return;
        }
        else {
            this.setState({ errorMessage: "Fill all required fields before save or disable paper gift card option if you don't need it" })
        }
    };

    private readonly discardEdit = () => {
        this.initializeStateFromAttributes()
    };

    private renderGiftCardInputs(){

        return (
            <div className={'cartline-giftcard'}>
                <div className={'giftcard_input-group-line'}>
                    <div className={'giftcard_input giftcard_error-message'}>  
                        <p>{this.state.errorMessage}</p>
                    </div>
                </div>
                <div className={'giftcard_input-group-line'}>
                    <div className={'giftcard_input'}>
                        <label id='ms-checkout-guest-profile__label' className=''>
                            {"From *"}
                        </label>
                        <input type='text' className='form-control'
                            onChange={this.onFromInputChangeHandler.bind(this)}
                            value={ this.state.from}/>
                    </div>
                </div>
                <div className={'giftcard_input-group-line'}>
                    <div className={'giftcard_input'}>
                        <label id='ms-checkout-guest-profile__label' className=''>
                            {"To *"}
                        </label>
                        <input type='text' className='form-control'
                            onChange={this.onToInputChangeHandler.bind(this)}
                            value={ this.state.to}/>
                    </div>
                </div>
                <div className={'giftcard_input-group-line'}>
                    <div className={'giftcard_input'}>
                        <label id='ms-checkout-guest-profile__label' className=''>
                            {"Message *"}
                        </label>
                        <textarea
                            className='form-control'
                            onChange={this.onMessageInputChangeHandler.bind(this)}
                            value={ this.state.message}
                        />
                    </div>
                </div>
                <div className={'giftcard_input-group-line'}>
                    <div className={'giftcard_input giftcard_file_input '}>
                        { 
                            (!this.state.image) && 
                            <label 
                                id='ms-checkout-guest-profile__label' 
                                className='input-file upload-photo'>
                                <input
                                    id='giftcard-upload-photo'
                                    type='file'
                                    className='form-control upload-photo'
                                    onChange={this.onImageInputChangeHandler.bind(this)}
                                    accept="image/*"
                                />
                                <span className="input-file-btn">Choose image for gift card</span>  
                            </label>
                        }
                        {   
                            (this.state.image) && 
                            <div className="input-file-list">
                                <div className="input-file-list-item">
                                    <img className="input-file-list-img" src={this.state.image}></img>
                                    <span className="input-file-list-name">{this.state.imageName}</span>
                                    {/*<a href="#" onClick={this.cleanImageHandler.bind(this)} className="input-file-list-remove">&times;</a>*/}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={'giftcard_input-group-line giftcard_actions'}>
                    {
                        this.state.isProcesssing && 
                        <div className={'giftcard_input action-button'}>
                            <span className="input-file-list-name">Saving..</span>
                        </div>
                    }
                    {
                        this.isSaveButtonVisible && !this.state.isProcesssing &&
                        <div className={'giftcard_input action-button'}>
                            <span onClick={this.savePaperGiftCardInfo.bind(this)} className="input-file-list-name">Save</span>
                        </div>
                    }
                    {
                        (this.state.image) && !this.state.isProcesssing &&
                        <div className={'giftcard_input action-button'}>
                            <span onClick={this.cleanImageHandler.bind(this)} className="input-file-list-name">Remove image</span>
                        </div>
                    }
                    {
                        this.isCancelVisible&& !this.state.isProcesssing &&
                        <div className={'giftcard_input action-button'}>
                            <span onClick={this.discardEdit.bind(this)} className="input-file-list-name">Cancel Edit</span>
                        </div>
                    }
                </div>
            </div>
        )
    }

    private renderGiftWrappingToggle(cartLine: ICartlinesViewProps, ctx: ICoreContext, resources: IGiftCardResources): JSX.Element | null {
        let value = getBooleanAttributeValue(cartLine?.data?.cartline?.AttributeValues!, needPaperGiftCardAttribute, false);
        const toggleState = value === true && 'enable' || 'disable';
        const buttonValue = value === true && 'true' || 'false';
        const ariaPressed = value;
        const className = "giftwrapping";
    
        return (
            <div className={classNames('ms-account-profile', 'ms-account-profile__toggle', `ms-account-profile-${className}`)}>
                <h3 className='ms-account-profile-heading'>{resources.toggleLabel}</h3>
                {(
                    <div className={`ms-account-profile__toggle-wrapper ms-account-profile__toggle-${toggleState}`}>
    
                        <span className='ms-account-profile__toggle-disable-text'>{resources.disableGiftwrappingLabel}</span>
                        <Button
                            className={classNames('ms-account-profile__toggle-button', `ms-account-profile__toggle-${toggleState}-button`)}
                            aria-label={`${resources.toggleLabel}`}
                            aria-pressed={ariaPressed}
                            value={buttonValue}
                            onClick={this.updateProductNeedPaperGiftCard(cartLine, ctx, !value)}
                            disabled={false}
                        />
                        <span className='ms-account-profile__toggle-enable-text'>{resources.enableGiftwrappingLabel}</span>
                    </div>
                )}
            </div>
        );
    };

    private updateProductNeedPaperGiftCard(cartLine: ICartlinesViewProps, context: ICoreContext, value: boolean){
        return async ( ) => {
            const actionContext = context.actionContext as IActionContext;
            if (actionContext) {
                const cartState = await getCartState(actionContext);
                const cartLineToUpdate = cartState?.cart?.CartLines?.find(x => x.LineId === cartLine.cartlineId);
                if (cartLineToUpdate) {
    
                    //clean paper gift card data from attributes
                    cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues || [];
                    cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues?.filter(x => x.Name !== paperGiftCardFromAttribute);
                    cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues?.filter(x => x.Name !== paperGiftCardToAttribute);
                    cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues?.filter(x => x.Name !== paperGiftCardMessageAttribute);
                    cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues?.filter(x => x.Name !== paperGiftCardImageAttribute);
    
                    const attributeValue = cartLineToUpdate.AttributeValues?.find(x => x.Name == needPaperGiftCardAttribute)               
                    if (attributeValue) {
                        updateBooleanAttribute(attributeValue as AttributeTextValue, value);
                    } else {
                        cartLineToUpdate.AttributeValues?.push(createBooleanAttribute(needPaperGiftCardAttribute, value));
                    }
    
                    await updateCartLinesAsync({ callerContext: actionContext }, cartState?.cart.Id, cartState?.cart?.CartLines);
                    await cartState.refreshCart({});
                    this.initializeStateFromAttributes();
                }
            }
        }
    };
};

const uploadPaperGiftCardImage = async (actionContext: IActionContext, imageBase64: string, imageName: string): Promise<string> => {
    let imageUrl = "";
    const context = { callerContext: actionContext };

    try {
        const imageData = await trusUploadImageAsync(context, imageBase64, imageName);
        if (imageData && imageData.imageUrl) {
            imageUrl = imageData.imageUrl;
        }
    }
    catch {
        imageUrl = "";
    }

    return imageUrl;
};

const updatePaperGiftCardInfo = async (cartLine: ICartlinesViewProps, context: ICoreContext, to: string, from: string, message: string, image: string) => {
    const actionContext = context.actionContext as IActionContext;
    if (actionContext) {
        const cartState = await getCartState(actionContext);
        const cartLineToUpdate = cartState?.cart?.CartLines?.find(x => x.LineId === cartLine.cartlineId);
        if (cartLineToUpdate) {
            cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues || [];
            let changeSet = [ 
                { attribute: paperGiftCardFromAttribute, value: from },
                { attribute: paperGiftCardToAttribute, value: to }, 
                { attribute: paperGiftCardMessageAttribute, value: message }, 
                { attribute: paperGiftCardImageAttribute, value: image }
            ]
            changeSet.map(x => {
                let attributeValue = cartLineToUpdate.AttributeValues?.find(a => a.Name === x.attribute)                   
                if (attributeValue) {
                    updateTextAttribute(attributeValue as AttributeTextValue, x.value!);
                } else {
                    cartLineToUpdate.AttributeValues?.push(createTextAttribute(x.attribute!, x.value!));
                }
            });
            await updateCartLinesAsync({ callerContext: actionContext }, cartState?.cart.Id, cartState?.cart?.CartLines);
            await cartState.refreshCart({});
        }
    }   
};

const isWrappingNeeded = (cartLine: ICartlinesViewProps) => {
    return getBooleanAttributeValue(cartLine?.data?.cartline?.AttributeValues!, needPaperGiftCardAttribute, false);
};