import React, { useState } from "react";
import classNames from 'classnames';
import { updateCartLinesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import { getAttributeValuesAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/ProductsDataActions.g';
import {
    CultureInfoFormatter
} from '@msdyn365-commerce/core-internal';
import { AttributeTextValue, SimpleProduct, AttributeValue } from '@msdyn365-commerce/retail-proxy';
import { ICoreContext, IActionContext } from '@msdyn365-commerce/core';
import { ICartlinesViewProps } from '@msdyn365-commerce-modules/cart/src/modules/cart/components/cart-line-items';
import { getCartState } from '@msdyn365-commerce/global-state';
import { Button} from '@msdyn365-commerce-modules/utilities';
import { Node } from '@msdyn365-commerce-modules/utilities';
import { ICartConfig, ICartResources } from "../../definition-extensions/cart.ext.props.autogenerated";
import { createBooleanAttribute, createTextAttribute, getAttributeValueOrDefault, getBooleanAttributeValue, getTextAttributeValue, updateBooleanAttribute, updateTextAttribute } from "../../../../utilities/attribute-utilities";
import { getProductsByMasterProductId } from "../../../../utilities/product-utilities";

export interface IGiftWrapppingComponentProps {
    cartLine: ICartlinesViewProps
    allLines: ICartlinesViewProps[]
    masterProduct?: number;
    channelId?: number;
    context?: ICoreContext | undefined;
    resources?: ICartResources | undefined;
    configs?: ICartConfig | undefined;
}

export interface IGiftWrappingResources{
    toggleLabel?: string;
    disableGiftwrappingLabel?: string;
    enableGiftwrappingLabel?: string;
}

const wrappingProductAttrName: string = "TRUS_CartLineWrappingProductId";
const cartLineNeedWrappingAttrName: string = "TRUS_CartLineNeedWrapping";
const wrappingProductNumberAttrName: string = "TRUS_CartLineWrappingProductCaclNumber";
const enableWrappingAttrName: string = "Gift Wrapping";

export const GiftWrappping: React.FC<IGiftWrapppingComponentProps> = ({context, cartLine, allLines, configs, resources}) => {

    const masterWrappingProductId = configs?.masterProductId;
    const giftWrappingResources: IGiftWrappingResources = {
        toggleLabel: resources?.giftWrappingSectionTitle || '',
        enableGiftwrappingLabel: resources?.enableGiftwrappingLabel || '',
        disableGiftwrappingLabel: resources?.disableGiftwrappingLabel || '',
    }

    const channelId = context?.actionContext?.requestContext?.apiSettings?.channelId!;
    const actionContext = context?.actionContext as IActionContext;
    
    initializeTelementryAndCultureFormatterIfMissing(context!);

    const [wrappingProducts, setWrappingProducts] = useState<SimpleProduct[]>([])
    const [wrappingEnabled, setWrappingEnabled] = useState<boolean>(false)

    React.useEffect(() => {
        if (masterWrappingProductId) {
            const fetchData = async () => {
                const productAttributes = await getProductAttributes(cartLine.data?.cartline.ProductId!, channelId, actionContext);
                const isWrappingEnabledForProduct = getAttributeValueOrDefault(productAttributes, enableWrappingAttrName, (x) => x.BooleanValue, true);

                setWrappingEnabled(isWrappingEnabledForProduct);

                if (isWrappingEnabledForProduct) {
                    const products = await getProductsByMasterProductId(masterWrappingProductId, channelId, actionContext);
                    setWrappingProducts(products)
                }
            };
            fetchData().catch(console.error);
        }
        else {
            console.error("Invalid master product id provided")
        }
    },[]);

    return (
        <>
        {   
            wrappingEnabled &&
            <Node className="msc-cart-line__giftwrapping-container" >
                {renderEnableGiftWrappingToggle(cartLine, context!, giftWrappingResources, wrappingProducts)}
                {
                    isCartLineNeedWrapping(cartLine) && 
                    <div>
                        <div className="giftwrapping_variants-container">
                            { wrappingProducts.map(product => renderWrappingProduct(cartLine, allLines, product, context!)) }
                        </div>
                    </div>
                }
            </Node>
        }
        </>
    );
};

function initializeTelementryAndCultureFormatterIfMissing(context: ICoreContext){
    const actionContext = context?.actionContext as IActionContext;
    if(context && !context.telemetry)
        context.telemetry = actionContext.telemetry;
    if(context && !context.cultureFormatter)
        context.cultureFormatter = new CultureInfoFormatter(
            actionContext?.requestContext?.locale,
            actionContext?.requestContext?.channel?.Currency ?? "SAR",
            actionContext?.requestContext?.query
        )
}

function renderWrappingProduct(cartLine: ICartlinesViewProps, allLines: ICartlinesViewProps[], product: SimpleProduct, context: ICoreContext){
    const variantClassName = classNames("giftwrapping_variant", getSelectedWrappingProductId(cartLine, allLines) === product.RecordId ? "selected-wrapping" : "");
    return (
        <div className={variantClassName} onClick={updateSelectedWrappingVariant(cartLine, product, context)}>
            <div className="giftwrapping_variant-image">
                <img className="giftwrapping_variant-image" src={product && product.PrimaryImageUrl}/>
            </div>
            <div className="giftwrapping_variant-details">
                <div className="giftwrapping_variant-price">
                    {context?.cultureFormatter?.formatCurrency(product.AdjustedPrice)}
                </div>
            </div>
        </div>
    )
}

const renderEnableGiftWrappingToggle = (cartLine: ICartlinesViewProps, ctx: ICoreContext, resources: IGiftWrappingResources, wrappingProducts?: SimpleProduct[]): JSX.Element | null => {
    let value = getBooleanAttributeValue(cartLine?.data?.cartline?.AttributeValues!, cartLineNeedWrappingAttrName, false);
    const toggleState = value === true && 'enable' || 'disable';
    const buttonValue = value === true && 'true' || 'false';
    const ariaPressed = value;
    const className = "giftwrapping";

    return (
        <div className={classNames('ms-account-profile', 'ms-account-profile__toggle', `ms-account-profile-${className}`)}>
            <h3 className='ms-account-profile-heading'>{resources.toggleLabel}</h3>
            {(
                <div className={`ms-account-profile__toggle-wrapper ms-account-profile__toggle-${toggleState}`}>

                    <span className='ms-account-profile__toggle-disable-text'>{resources.disableGiftwrappingLabel}</span>
                    <Button
                        className={classNames('ms-account-profile__toggle-button', `ms-account-profile__toggle-${toggleState}-button`)}
                        aria-label={`${resources.toggleLabel}`}
                        aria-pressed={ariaPressed}
                        value={buttonValue}
                        onClick={updateIsCartLineNeedWrapping(cartLine, ctx, !value, wrappingProducts)}
                        disabled={false}
                    />
                    <span className='ms-account-profile__toggle-enable-text'>{resources.enableGiftwrappingLabel}</span>
                </div>
            )}
        </div>
    );
};

const updateSelectedWrappingVariant = (cartLine: ICartlinesViewProps, product: SimpleProduct, context: ICoreContext) => {
    return async () => {
        const actionContext = context.actionContext;
        const cartState = await getCartState(actionContext);
        const cartLineToUpdate = cartState?.cart?.CartLines?.find(x => x.LineId === cartLine.cartlineId);
        if (cartLineToUpdate) {
            cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues || [];
            let changeSet = [ 
                { attributeName: wrappingProductAttrName, value: product.RecordId.toString() },
                { attributeName: wrappingProductNumberAttrName, value: calculateProductNumber(product) }
            ]
            changeSet.map(x => {
                let attributeValue = cartLineToUpdate.AttributeValues?.find(a => a.Name === x.attributeName)                   
                if (attributeValue) {
                    updateTextAttribute(attributeValue as AttributeTextValue, x.value!);
                } else {
                    cartLineToUpdate.AttributeValues?.push(createTextAttribute(x.attributeName!, x.value!));
                }
            });
            await updateCartLinesAsync({ callerContext: actionContext }, cartState?.cart.Id, cartState?.cart?.CartLines);
            await cartState.refreshCart({});
        }    
    }
};

const updateIsCartLineNeedWrapping = (cartLine: ICartlinesViewProps, context: ICoreContext, value: boolean, wrappingProducts?: SimpleProduct[]) => {
    return async ( ) => {
        const actionContext = context.actionContext;
        const cartState = await getCartState(actionContext);
        const cartLineToUpdate = cartState?.cart?.CartLines?.find(x => x.LineId === cartLine.cartlineId);
        if (cartLineToUpdate) {
            cartLineToUpdate.AttributeValues = cartLineToUpdate.AttributeValues?.filter(x => x.Name !== wrappingProductAttrName && x.Name !== wrappingProductNumberAttrName) ?? [];

            let changeSet: any[] = [ 
                { attributeName: cartLineNeedWrappingAttrName, value: value, type: "boolean" }
            ]

            if (value === true && wrappingProducts && wrappingProducts.length > 0) {
                let product = wrappingProducts[0];
                changeSet.push({ attributeName: wrappingProductAttrName, value: product.RecordId.toString(), type: "text" })
                changeSet.push({ attributeName: wrappingProductNumberAttrName, value: calculateProductNumber(product), type: "text" })
            }

            changeSet.map(x => {
                let attributeValue = cartLineToUpdate.AttributeValues?.find(a => a.Name === x.attributeName)                   
                if (attributeValue) {
                    if(x.type === "text")
                        updateTextAttribute(attributeValue as AttributeTextValue, x.value!);
                    if(x.type === "boolean")
                        updateBooleanAttribute(attributeValue as AttributeTextValue, x.value!);
                } else {
                    if(x.type === "text")
                        cartLineToUpdate.AttributeValues?.push(createTextAttribute(x.attributeName!, x.value!));
                    if(x.type === "boolean")
                        cartLineToUpdate.AttributeValues?.push(createBooleanAttribute(x.attributeName!, x.value!));
                }
            });

            await updateCartLinesAsync({ callerContext: actionContext }, cartState?.cart.Id, cartState?.cart?.CartLines);
            await cartState.refreshCart({});
        }
    }
};

async function getProductAttributes(productId: number, channelId: number, actionContext: IActionContext): Promise<AttributeValue[]>  {
    return await getAttributeValuesAsync({ callerContext: actionContext}, productId, channelId, 0);
}

const isCartLineNeedWrapping = (cartLine: ICartlinesViewProps) => {
    return getBooleanAttributeValue(cartLine?.data?.cartline?.AttributeValues!, cartLineNeedWrappingAttrName, false);
};

const getSelectedWrappingProductId = (cartLine: ICartlinesViewProps, allLines: ICartlinesViewProps[]) => {
    const wrappingProductId = getTextAttributeValue(cartLine?.data?.cartline?.AttributeValues!, wrappingProductAttrName);
    return wrappingProductId ? parseInt(wrappingProductId) : null;
};

const calculateProductNumber = (product: SimpleProduct) => {
    if (product) {
        return `${product.ItemId}-${product.Dimensions?.map(x => x?.DimensionValue?.DimensionId).join(",")}`;
    }
    return '';
};