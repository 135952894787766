import { AttributeTextValue, AttributeValue, AttributeValueBase } from "@msdyn365-commerce/retail-proxy";

export const updateTextAttribute = (attribute: AttributeTextValue, value: string) => {
    if (attribute) {
        attribute.TextValue = value;
        attribute.TextValueTranslations = [];
        attribute['@odata.type'] = "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue";
    } 
    return attribute;
};

export const updateBooleanAttribute = (attribute: AttributeTextValue, value: boolean) => {
    return updateTextAttribute(attribute, value === true ? "true" : "false");
};

export const createBooleanAttribute = (name: string, value: boolean) => {
    return createTextAttribute(name, value === true ? "true" : "false")
};

export const createTextAttribute = (name: string, value: string) => {
    return { 
        Name : name, 
        TextValue: value,
        TextValueTranslations: [],
        '@odata.type': "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue"
    };
};

export const getTextAttributeValue = (attributes: AttributeValueBase[], name: string) => {
    if (attributes) {
        const attribute = attributes?.find(x => x.Name == name) as AttributeTextValue        
        if (attribute) {
            return attribute.TextValue;
        }
    }
    return null;
};

export const getBooleanAttributeValue = (attributes: AttributeValueBase[], name: string, defaultVal: boolean = false) => {
    const textValue = getTextAttributeValue(attributes, name);
    if (textValue) {
        return textValue.toLowerCase() === "true" ? true : false;
    }
    return defaultVal;
};

export const getAttributeValueOrDefault = (attributes: AttributeValue[], name: string, valueSelector: (x: AttributeValue) => any, defaultVal: boolean = false) => {
    const attribute = attributes.find(x => x.Name === name);
    if (attribute) {
        const attributeValue = valueSelector(attribute);
        if (attributeValue === undefined || attributeValue === null) {
            return defaultVal
        }
        return attributeValue;
    }
    return defaultVal;
};