export interface IImageResponse {
    imageUrl: string;
    errorMessage?: string;
}

export class ImageResponseClass implements IImageResponse {
    public imageUrl: string;
    public errorMessage?: string;

    constructor(odataObject?: any) {
        odataObject = odataObject || {};

        this.imageUrl = odataObject.imageUrl;
        this.errorMessage = odataObject.errorMessage;
    }
}